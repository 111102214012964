import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators';
import defaultStyles from '../styles/styles';

import './main';

@customElement('x-app')
export default class App extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
      <div class="container">
        <div class="row">
          <div class="column">
            <h3>Reservation Stanserhorn</h3>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <x-menu></x-menu>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <x-main></x-main>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <p>
              Sie erreichen uns telefonisch unter 041 618 80 40.<br>
              Bis zu 9 Personen können direkt online angemeldet werden. Für grössere Gruppen bitten wir Sie uns anzurufen.
            </p>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-app': App,
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store, { BookingOption } from '../store';
import defaultStyles from '../styles/styles';

import './menu';
import './event-group';
import './event';
import '../api/apiclient';
import './num-people';
import './booking-form';
import './summary';
import './confirmation';

@customElement('x-main')
export default class Main extends LitElement {
  static styles = [
    css`
    :host{
      margin-top: 1rem;
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Number })
  step = store.state.step;

  @property({ type: Array })
  bookingOptions = store.state.bookingOptions;

  private handleStateStep = (e : Event) => {
    this.step = (e as CustomEvent).detail.data as number;
  };

  private handleStateBookingOptions = (e : Event) => {
    this.bookingOptions = (e as CustomEvent).detail.data as BookingOption[];
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:step', this.handleStateStep);
    window.addEventListener('appstate:bookingOptions', this.handleStateBookingOptions);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:step', this.handleStateStep);
    window.removeEventListener('appstate:bookingOptions', this.handleStateBookingOptions);
    super.disconnectedCallback();
  }

  render() {
    switch (this.step) {
      case 0:
        return html`<x-event-group></x-event-group>`;
      case 1:
        return html`<x-num-people></x-num-people>`;
      case 2:
        return html`<x-event></x-event>`;
      case 3:
        return html`<x-booking-form></x-booking-form>`;
      case 4:
        return html`<x-summary></x-summary>`;
      case 5:
        return html`<x-confirmation></x-confirmation>`;
      default:
        return html`<p>Es ist ein Fehler aufgetreten - bitte laden Sie dieses Fenster neu.</p>`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-main': Main,
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators';
import store, {BookingOption} from '../store';
import defaultStyles from '../styles/styles';

import './button-previous';
import './button-next';

@customElement('x-booking-form')
export default class BookingForm extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Object })
  body : { [key: string] : any } = {};

  @property({ type: Boolean })
  isFormValid = store.state.validBooking;

  @query('#res-form')
  form! : HTMLFormElement;

  private handleStateValidBooking = (e : Event) => {
    this.isFormValid = (e as CustomEvent).detail.data;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:validBooking', this.handleStateValidBooking);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:validBooking', this.handleStateValidBooking);
    super.disconnectedCallback();
  }

  // handle if form values change
  handleFormChange = (e: { target: HTMLInputElement }) => {
    store.set('booking', {
      ...store.state.booking,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
    // todo : custom validation
    const valid = this.form.checkValidity();
    if (valid !== this.isFormValid) {
      store.set('validBooking', valid);
    }
  };

  render() {
    const { booking, bookingOption } = store.state;
    if (bookingOption === undefined) {
      return html`
        <p>Es ist leider ein unerwarteter Fehler aufgetreten. Bitte laden Sie dieses Fenster neu.
        Bei Fragen können Sie sich gerne auch telefonisch an uns wenden.</p>
      `;
    }
    return html`
      <h5>${bookingOption.name}: ${new Date(bookingOption.when).toLocaleDateString('de-CH')}</h5>
      <p>Bitte geben Sie für die Reservation Ihre Personalien ein:</p>
      <div id="form-wrapper">
        <form id="res-form" @input=${this.handleFormChange}>
          <div class="row">
            <div class="column">
              <label for="title">Anrede*</label>
              <select class="u-full-width" name="title" id="title" required>
                <option value="Frau" ?selected=${booking.title === 'Frau'}>Frau</option>
                <option value="Frau und Herr" ?selected=${booking.title === 'Frau und Herr'}>Frau und Herr</option>
                <option value="Herr" ?selected=${booking.title === 'Herr'}>Herr</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="firstName">Vorname*</label>
              <input class="u-full-width" type="text" name="firstName" id="firstName" value="${booking.firstName}" required>
            </div>
          </div>
          <div class="row">
          <div class="column">
              <label for="lastName">Nachname*</label>
              <input class="u-full-width" type="text" name="lastName" id="lastName" value="${booking.lastName}"  required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="street">Strasse*</label>
              <input class="u-full-width" type="text" name="street" id="street" value="${booking.street}" required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="streetNumber">Nummer</label>
              <input class="u-full-width" type="text" name="streetNumber" id="streetNumber" value="${booking.streetNumber}" >
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="postalCode">Postleitzahl*</label>
              <input class="u-full-width" type="text" name="postalCode" id="postalCode" value="${booking.postalCode}" required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="city">Ort*</label>
              <input class="u-full-width" type="text" name="city" id="city" value="${booking.city}" required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="phone">Telefonnummer*</label>
              <input class="u-full-width" type="text" name="phone" id="phone" value="${booking.phone}" required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="mail">E-Mail Adresse*</label>
              <input class="u-full-width" type="email" name="mail" id="mail" value="${booking.mail}" required>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <input type="checkbox" name="newsletter" id="newsletter" ?checked=${booking.newsletter}>
              <label class="label-inline" for="newsletter">Ich möchte gerne den Newsletter der Stanserhorn-Bahn AG erhalten.</label>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <label for="remarks">Anmerkungen (Allergien, Wünsche, etc)</label>
              <textarea class="u-full-width" id="remarks" name="remarks" >${booking.remarks}</textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="buttons">
        <x-button-previous></x-button-previous>
        <x-button-next ?disabled=${!this.isFormValid}></x-button-next>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-booking-form': BookingForm,
  }
}

import store, { BookingOption } from '../store';

// ApiClient to query horn server
export default class ApiClient {
  basePath: string;

  constructor(basePath : string | undefined) {
    this.basePath = basePath || '';
  }

  /**
   * request sends a request to an api endpoint
   *
   * @param endpoint path to api endpoint
   * @param options RequestInit options for the request
   */
  async request(endpoint = '', options : RequestInit | undefined) : Promise<unknown> {
    const url = this.basePath + endpoint;
    const opts = options || {};
    opts.headers = opts.headers || {
      'Content-type': 'application / json',
    };
    const response = await fetch(url, opts);
    if (response.ok) {
      return (response.json());
    }
    const text = await response.text();
    throw Error(text || response.statusText);
  }

  /**
   * getBookingOptions queries all the bookable events
   */
  async getBookingOptions() : Promise<BookingOption[]> {
    const options : RequestInit = {
      method: 'GET',
    };
    const response = this.request('/api/bookingoptions', options) as Promise<BookingOption[]>;
    return response;
  }

  /**
   * postBooking posts the booking from the frontend's local store
   */
  async postBooking() : Promise<void> {
    const { booking, numPeople, bookingOption } = store.state;
    booking.eventId = bookingOption?.id || 0;
    booking.count = numPeople;
    if (booking.streetNumber !== '') {
      booking.street += ` ${booking.streetNumber}`;
      booking.streetNumber = '';
    }
    const options : RequestInit = {
      method: 'POST',
      body: JSON.stringify(booking),
    };
    await this.request('/api/bookings', options);
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store from '../store';
import defaultStyles from '../styles/styles';

@customElement('x-button-next')
export default class ButtonNext extends LitElement {
  static styles = [
    css`
    :host{
      display: inline;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Boolean })
  disabled = false;

  handleClick = (event : Event) => {
    event.preventDefault();
    const { step } = store.state;
    store.set('step', step + 1);
  };

  render() {
    const { step } = store.state;
    if (step < 5) {
      return html`<button ?disabled=${this.disabled} class="button button-custom" id="next" @click=${this.handleClick}>Weiter</button>`;
    }
    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-button-next': ButtonNext,
  }
}

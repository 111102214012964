import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import store, { AppState } from "../store";
import defaultStyles from "../styles/styles";

import "./button-previous";
import "./button-book";

@customElement("x-summary")
export default class Summary extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }
    `,
    ...defaultStyles,
  ];

  @property({ type: String })
  saveBookingError = store.state.saveBookingError;

  private handleStateSaveBookingError = (e: Event) => {
    this.saveBookingError = (e as CustomEvent).detail.data as string;
  };

  private handlePrivacyCheckboxChange = (e: { target: HTMLInputElement }) => {
    store.set("privacyAccepted", e.target.checked);
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(
      "appstate:saveBookingError",
      this.handleStateSaveBookingError
    );
  }

  disconnectedCallback() {
    window.removeEventListener(
      "appstate:saveBookingError",
      this.handleStateSaveBookingError
    );
    super.disconnectedCallback();
  }

  render() {
    const { bookingOption, booking, numPeople } = store.state as AppState;
    if (bookingOption === undefined) {
      return html`
        <p>
          Es ist leider ein unerwarteter Fehler aufgetreten. Bitte laden Sie
          dieses Fenster neu. Bei Fragen können Sie sich gerne auch telefonisch
          an uns wenden.
        </p>
      `;
    }
    let standbyText = html``;
    const seatsAvailable = bookingOption.seatsAvailable - numPeople;
    if (seatsAvailable < 0) {
      standbyText = html`
        <div class="row">
          <div class="column">
            <p>
              <b>Wichtiger Hinweis</b>: Da dieser Anlass bereits ausgebucht ist,
              tragen Sie sich durch das Absenden Ihrer Daten in die Warteliste
              ein. Ihre Position auf der Warteliste ist
              <b>${bookingOption.seatsStandBy + 1}</b>.
              ${bookingOption.seatsStandBy === 0
                ? ""
                : `Es sind somit bereits mehrere Buchungen vorhanden, welche gesamthaft ${bookingOption.seatsStandBy} Plätze in Anspruch nehmen würden.`}
            </p>
          </div>
        </div>
      `;
    }
    let errorBox = html``;
    if (this.saveBookingError !== "") {
      errorBox = html`
        <div class="row">
          <div class="column error">
            Ihre Reservationsdaten konnten aufgrund eines Fehlers leider nicht
            gespeichert werden: ${this.saveBookingError}<br />
            Bitte ändern Sie Ihre Daten und versuchen Sie es erneut oder melden
            Sie sich telefonisch bei uns, sofern das Problem nicht behoben
            werden kann.
          </div>
        </div>
      `;
    }
    return html`
      <h5>
        ${bookingOption.name}:
        ${new Date(bookingOption.when).toLocaleDateString("de-CH")}
      </h5>
      ${standbyText}
      <div class="row">
        <div class="column">
          <p>
            ${booking.title} ${booking.firstName} ${booking.lastName}<br />
            ${booking.street} ${booking.streetNumber}<br />
            ${booking.postalCode} ${booking.city}<br />
            T: ${booking.phone}<br />
            M: ${booking.mail}<br />
            Newsletter: ${booking.newsletter ? "Ja" : "Nein"}<br />
            Anzahl Personen: ${numPeople}<br />
            ---<br />
            ${booking.remarks}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="column float-left">
          <input
            type="checkbox"
            id="acceptPrivacy"
            name="acceptPrivacy"
            @change=${this.handlePrivacyCheckboxChange}
          />
          <label class="label-inline" for="acceptPrivacy">
            Datenschutzerklärung akzeptieren
          </label>
          <p>
            Ich stimme zu, dass die Stanserhorn-Bahn AG die oben angegebenen
            persönlichen Daten speichert und verarbeitet, um die angeforderten
            Inhalte bereitzustellen. Die
            <a href="https://www.stanserhorn.ch/datenschutz" target="_blank"
              >Datenschutzerklärung</a
            >
            habe ich gelesen und stimme dieser ausdrücklich zu.
          </p>
        </div>
      </div>
      ${errorBox}
      <div class="buttons">
        <x-button-previous></x-button-previous>
        <x-button-book></x-button-book>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "x-summary": Summary;
  }
}

import { css, html, LitElement } from 'lit';
import {customElement, property} from 'lit/decorators';
import ApiClient from '../api/apiclient';
import store from '../store';
import defaultStyles from '../styles/styles';

@customElement('x-button-book')
export default class ButtonBook extends LitElement {
  static styles = [
    css`
    :host{
      display: inline;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: String })
  privacyAccepted = store.state.privacyAccepted;

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:privacyAccepted', this.handleStateSavePrivacyAccepted);
  }

  private handleStateSavePrivacyAccepted = (e : Event) => {
    this.privacyAccepted = (e as CustomEvent).detail.data as boolean;
  };

  handleClick = async (event : Event) => {
    event.preventDefault();
    const api = new ApiClient(process.env.API_BASE_PATH);
    try {
      await api.postBooking();
      const { step } = store.state;
      store.set('saveBookingError', '');
      store.set('step', step + 1);
    } catch (e) {
      store.set('saveBookingError', (e as Error).message);
    }
  };

  render() {
    return html`<button class="button button-custom" id="reserve" @click=${this.handleClick} ?disabled=${!this.privacyAccepted}>Daten absenden</button>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-button-book': ButtonBook,
  }
}

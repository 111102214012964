import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators';
import defaultStyles from '../styles/styles';

@customElement('x-confirmation')
export default class Confirmation extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
    <h5>Vielen Dank!</h5>
    <p>
      Ihre Daten wurden erfolgreich übermittelt. Sie können dieses Fenster nun schliessen oder zu <a href="https://www.cabrio.ch">www.cabrio.ch</a> navigieren.
    </p>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-confirmation': Confirmation,
  }
}

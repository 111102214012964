import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store from '../store';
import defaultStyles from '../styles/styles';

import './button-previous';
import './button-next';

@customElement('x-num-people')
export default class NumPeople extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Number })
  numPeople = store.state.numPeople;

  @property({ type: Array })
  bookingOptions = store.state.bookingOptions;

  private handleStateNumPeople = (e : Event) => {
    this.numPeople = (e as CustomEvent).detail.data || 0 as number;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:numPeople', this.handleStateNumPeople);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:numPeople', this.handleStateNumPeople);
    super.disconnectedCallback();
  }

  handleInput = (e : Event) : void => {
    const { value } = (<HTMLInputElement>e.target);
    store.set('numPeople', parseInt(value, 10));
  };

  render() {
    return html`
      <p>Bitte geben Sie die gwünschte Anzahl Personen ein (1 - 9).</p>
      <input type="number" @input=${this.handleInput} value=${this.numPeople} min="1" max="9">
      <div id="buttons">
        <x-button-previous></x-button-previous>
        <x-button-next ?disabled=${this.numPeople < 1 || this.numPeople > 9}></x-button-next>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-num-people': NumPeople,
  }
}

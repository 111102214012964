import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store from '../store';
import defaultStyles from '../styles/styles';

@customElement('x-menu')
export default class Menu extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    @media screen and (max-width:720px){
      :host {
        display:none;
      }
    }

    ul {
      border-radius: 10px;
      color: #333;
      list-style-type: none;
      margin: 0;
      padding: 0;
      float: left;
      clear: both;
    }

    li {
      display: block;
      float: left;
      background-color: white;
      padding: 10px;
    }

    li.active {
      color: white;
      background-color: #ff9900;
    }

    li:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    li:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Number })
  step = store.state.step;

  private handleStateStep = (e : Event) => {
    this.step = (e as CustomEvent).detail.data as number;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:step', this.handleStateStep);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:step', this.handleStateStep);
    super.disconnectedCallback();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { step } = store.state;
    return html`
      <div class="menu-wrapper">
        <ul class="menu">
          <li class="${step === 0 ? 'active' : ''}">Veranstaltungen</li>
          <li class="${step === 1 ? 'active' : ''}">Personen</li>
          <li class="${step === 2 ? 'active' : ''}">Datum</li>
          <li class="${step === 3 ? 'active' : ''}">Adresse</li>
          <li class="${step === 4 ? 'active' : ''}">Zusammenfassung</li>
          <li class="${step === 5 ? 'active' : ''}">Bestätigung</li>
        </ul>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-menu': Menu,
  }
}

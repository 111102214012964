import ApplicationStore, { Store } from './lib/store';

export type BookingOption = {
  id : number
  group : string
  name : string
  when : string
  seatsAvailable : number
  seatsStandBy : number
};

export type Booking = {
  eventId : number
  title: string
  firstName: string
  lastName: string
  street: string
  streetNumber: string
  postalCode: string
  city: string
  phone: string
  mail: string
  count: number
  remarks : string
  newsletter : boolean
};

export type AppState = {
  step: number;
  bookingOptions: BookingOption[],
  eventGroup: string,
  numPeople: number;
  bookingOption: BookingOption | undefined,
  validBooking: boolean,
  booking: Booking,
  saveBookingError: string,
  privacyAccepted: boolean,
};

const initialState : AppState = {
  step: 0,
  bookingOptions: [],
  eventGroup: '',
  bookingOption: undefined,
  // bookingOption: {
  //   id: 1,
  //   name: 'Test',
  //   when: '2012-12-25 20:00',
  //   group: '',
  //   seatsAvailable: 50,
  //   seatsStandBy: 0,
  // },
  numPeople: 1,
  validBooking: false,
  saveBookingError: '',
  privacyAccepted: false,
  booking: {
    eventId: 0,
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    phone: '',
    mail: '',
    count: 0,
    remarks: '',
    newsletter: false,
  },
};

// save initial state, with persist set to false
export default new ApplicationStore(initialState, false);

// method to reset state
const resetStore = (store: Store<AppState>) : void => {
  store.resetState('reset');
};

export {
  resetStore,
};

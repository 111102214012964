import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store from '../store';
import defaultStyles from '../styles/styles';

import './button-next';

@customElement('x-event-group')
export default class EventGroup extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Array })
  bookingOptions = store.state.bookingOptions;

  @property({ type: String })
  eventGroup = store.state.eventGroup;

  private handleStateEventGroup = (e : Event) => {
    this.eventGroup = (e as CustomEvent).detail.data as string;
  };

  private handleStateBookingOptions = (e : Event) => {
    this.bookingOptions = (e as CustomEvent).detail.data;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:eventGroup', this.handleStateEventGroup);
    window.addEventListener('appstate:bookingOptions', this.handleStateBookingOptions);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:eventGroup', this.handleStateEventGroup);
    window.removeEventListener('appstate:bookingOptions', this.handleStateBookingOptions);
    super.disconnectedCallback();
  }

  handleChange = (event : Event) => {
    event.preventDefault();
    const { value } = (<HTMLInputElement>event.target);
    store.set('eventGroup', value);
  };

  render() {
    const eventGroupChosen = this.eventGroup !== '';
    if (this.bookingOptions.length === 0) {
      return html`
        <p>Aktuell können leider keine Anlässe gebucht werden.</p>
      `;
    }
    const groups : string[] = [];
    // get all unique event groups
    this.bookingOptions.forEach((opt) => {
      if (groups.indexOf(opt.group) === -1) {
        groups.push(opt.group);
      }
    });
    return html`
    <div>
      <p>Bitte wählen Sie die gewünschte Veranstaltung aus:</p>
      <select @change=${this.handleChange}>
        <option ?selected=${!eventGroupChosen} disabled>Veranstaltung wählen</option>
        ${groups.map((g) => html`<option ?selected=${this.eventGroup === g} value="${g}">${g}</option>`)}
      </select>
      <div class="buttons">
        <x-button-next ?disabled=${!eventGroupChosen}></x-button-next>
      </div>
    </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-event-group': EventGroup,
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';
import store, { BookingOption } from '../store';
import defaultStyles from '../styles/styles';

import './button-next';
import './button-previous';

// availabilityInfo creates a string to describe if there are seats available for the event.
const availabilityInfo = (ev : BookingOption, numPeople : number) => {
  const seatsAvailable = ev.seatsAvailable - numPeople;
  if (ev.seatsStandBy > 0 || seatsAvailable <= 0) {
    return html`
      - Buchung nur auf <span class="tooltip">Warteliste<span class="tooltiptext">
        Dieser Anlass ist leider bereits ausgebucht.
        Sie können sich jedoch provisorisch auf die Warteliste eintragen.
        Die Position gibt an, die wievielte Person Sie in diesem Fall auf der Warteliste einnehmen würden.
        Sofern ein Platz für Sie frei wird, werden Sie von uns kontaktiert.
      </span></span> möglich, Position ${ev.seatsStandBy + 1}
    `;
  }
  return '';
};

@customElement('x-event')
export default class EventComponent extends LitElement {
  static styles = [
    css`
    :host{
      display: block;
    }
    `,
    ...defaultStyles,
  ];

  @property({ type: Object })
  bookingOption : BookingOption | undefined = store.state.bookingOption;

  private handleStateBookingOption = (e : Event) => {
    this.bookingOption = (e as CustomEvent).detail.data as BookingOption;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('appstate:bookingOption', this.handleStateBookingOption);
  }

  disconnectedCallback() {
    window.removeEventListener('appstate:bookingOption', this.handleStateBookingOption);
    super.disconnectedCallback();
  }

  handleChange = (ev : BookingOption) : { handleEvent: () => void } => (
    {
      handleEvent: () : void => {
        store.set('bookingOption', ev);
      },
    });

  render() {
    let eventChosen = false;
    const {
      bookingOptions, eventGroup, numPeople,
    } = store.state;
    const opts : BookingOption[] = [];
    // get all events for given event group
    bookingOptions.forEach((opt) => {
      if (opt.group === eventGroup) {
        if (this.bookingOption && opt.id === this.bookingOption.id) {
          // if event is defined and still exists
          eventChosen = true;
        }
        opts.push(opt);
      }
    });
    return html`
      <fieldset>
      ${opts.map((opt) => html`
              <label for="${opt.id}">
                <input type="radio" name="event" id="${opt.id}" value="${opt.id}" ?checked=${this.bookingOption?.id === opt.id} @change=${this.handleChange(opt)}>
                <span class="label-body">${new Date(opt.when).toLocaleDateString('de-CH')} - ${opt.name} ${availabilityInfo(opt, numPeople)}</span>
              </label>
      `)}
      </fieldset>
      <div id="buttons">
        <x-button-previous></x-button-previous>
        <x-button-next ?disabled=${!eventChosen}></x-button-next>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'x-event': EventComponent,
  }
}

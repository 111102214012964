import store from './store';
import App from './components/app';
import ApiClient from './api/apiclient';

// load options
const api = new ApiClient(process.env.API_BASE_PATH);
api.getBookingOptions().then((bookingOptions) => {
  store.set('bookingOptions', bookingOptions);
});

new App().render();
